import { type Employee, type Event, EventState, EventType } from '@movalib/movalib-commons';
import { flexCenter, formatFrenchVehiclePlate } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import { Box, Typography, darken, useTheme } from '@mui/material';
import { differenceInMinutes, format } from 'date-fns';
import { lowerCase, startCase } from 'lodash';
// MyCustomEvent.tsx
import { type CSSProperties, type FunctionComponent, useState } from 'react';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import { Views } from 'react-big-calendar';
import { FIRE_EVENT_DEFAULT_HOURS, PALETTE_THIRD_COLOR_MAIN } from '../../helpers/Constants';
import { CustomTooltip } from '../../helpers/Styled';
import { flexStart, getEventStateColor, getEventStateLabel, isWithinLastIndicatedHours } from '../../helpers/Tools';
import UserAvatar from '../UserAvatar';
import type { ViewType } from './MyCalendarTypes';
import DeployedCodeHistory from '../../assets/images/icons/deployed_code_history.svg';

require('./MyCalendar.css');

const DEFAULT_ALERT_ICON_COLOR = 'white';

interface MyCalendarEventProps {
  event: Event;
  view: ViewType;
  teamManagement?: boolean;
}

const MyCalendarEvent: FunctionComponent<MyCalendarEventProps> = ({ event, view, teamManagement = false }) => {
  const [openTooltip, setOpenTooltip] = useState(event.state === EventState.NEW && view !== 'month');
  const theme = useTheme();

  // Fonction pour gérer le comportement de fermeture
  const handleTooltipClose = () => {
    if (event.state !== EventState.NEW) {
      setOpenTooltip(false); // Permet de fermer la Tooltip pour les événements non-NEW
    }
  };

  // Déterminez si la Tooltip doit être contrôlée ou non
  const isControlled = event.state === EventState.NEW;

  const now = new Date();
  const isPastEvent = event.end ? event.end < now : false;
  const isDoneEvent = event.state ? event.state === EventState.DONE : false;
  // Est-ce que la durée de l'event est égale à l'interval mini ?
  const isMinDuration = event.start && event.end ? differenceInMinutes(event.end, event.start) < 60 : false;
  const startHour = event.start ? new Date(event.start).getHours() : Number.NaN;
  const endHour = event.end ? new Date(event.end).getHours() : Number.NaN;
  const startMinute = event.start ? new Date(event.start).getMinutes() : Number.NaN;
  const endMinute = event.end ? new Date(event.end).getMinutes() : Number.NaN;
  const isDayEvent = startHour === endHour && startMinute === endMinute;
  const eventDuration = event?.start && event?.end && differenceInMinutes(event.end, event.start);

  const getEventStyle = (event: Event): CSSProperties => {
    let style = {};
    if (event?.start && event?.end) {
      const eventDuration = differenceInMinutes(event.end, event.start);
      switch (event.type) {
        case EventType.APPOINTMENT:
          style = {
            position: 'relative',
            fontFamily: 'Outfit',
            opacity: isPastEvent || isDoneEvent ? 0.5 : 1,
            fontSize: 13,
            height: '100%',
            paddingLeft: event.color ? '4px' : '',
            backgroundClip: 'padding-box',
          };
          break;
        case EventType.NOTE:
          style = {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            textAlign: 'center',
            fontSize: 14,
            opacity: isPastEvent ? 0.5 : 1,
            color: theme.palette.common.white,
            backgroundColor: '#4285F4',
            cursor: 'pointer',
            border: 'none !important',
            paddingTop: eventDuration && eventDuration > 30 && !isDayEvent ? '15px' : 0,
          };
          break;
        case EventType.UNAVAILABILITY:
          style = {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            fontSize: 14,
            opacity: isPastEvent ? 0.5 : 1,
            color: theme.palette.grey[500],
            textTransform: 'uppercase',
            cursor: event.editable ? 'pointer' : 'default',
            border: 'none !important',
          };
          break;
      }
    }

    return style;
  };

  const avatarStyle: CSSProperties = {
    position: 'absolute',
    top: '-6px',
    left: '-10px',
  };

  const diagonalStyle: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    background: 'repeating-linear-gradient(45deg, transparent, transparent 22px, #fff 22px, #fff 25px)',
    opacity: event.state === EventState.DONE ? 1 : 0, // n'affiche la superposition que si l'événement est clôturé
  };

   const markupIconStyle: CSSProperties = {
    position: 'absolute',
    padding: '2px',
    fontSize: '0.7rem',
    top: '10%',
    left: '100%',
    transform: 'translate(-50%, -50%)',
  };

  const markupTextStyle: CSSProperties = {
    backgroundColor: 'white',
    marginLeft: 'auto',
    marginBottom: '6px',
    marginRight: 'auto',
    padding: '2px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '85%',
    fontSize: '0.7rem',
  };

  const employee: Employee | undefined = event.employees?.[0] ? event.employees?.[0] : undefined;
  const showUserAvatar = teamManagement && !(view === Views.WORK_WEEK || view === Views.DAY);

  const getFirstLineMarginLeft = (): number => {
    // Si présence d'un avatar
    const isAvatarShowed = teamManagement && showUserAvatar && employee;
    // Décalage en nombre de pixel
    let pixQuantity = 0;

    pixQuantity = isAvatarShowed ? 14 : 0;
    pixQuantity += event?.vehicleReceived ? 18 : 0;

    return pixQuantity;
  };

  return (
    <CustomTooltip
      disableInteractive
      title={
        <Box sx={{ color: event.type === EventType.NOTE ? theme.palette.common.white : theme.palette.text.primary }}>
          <Typography
            style={{
              marginBottom: event.type !== EventType.APPOINTMENT ? '2px' : '8px',
              ...flexCenter,
              ...(event.state === EventState.NEW && { ...flexCenter }),
            }}
            className={event.type === EventType.NOTE && view === Views.WORK_WEEK ? '' : 'styled-white-alert'}
          >
            {/*             {event.state === EventState.NEW && (
              <QuestionMarkIcon
                sx={{
                  color: 'white',
                  fontSize: '0.9rem',
                  mr: 1,
                  display: event.state === EventState.NEW ? 'inherit' : 'none',
                }}
              />
            )} */}
            {getEventStateLabel(event)}
          </Typography>
          {/*           {event.type !== EventType.APPOINTMENT && (
            <Typography variant='body2'>
              <strong>{event.title}</strong>
            </Typography>
          )} */}
          {event.type === EventType.NOTE && isDayEvent && <Typography variant='body2'>{event.notes}</Typography>}
          {event.state === EventState.REJECTED && view !== 'month' && (
            <div style={markupTextStyle}>
              <b>DEVIS REFUSÉ</b>
            </div>
          )}
          {event.vehicleAvailableNotified && view !== 'month' && (
            <div style={markupTextStyle}>
              <MarkChatReadIcon sx={{ color: theme.palette.grey[800] }} /> &nbsp;
              <b>SMS FIN RDV ENVOYÉ</b>
            </div>
          )}
          {event.hasProductOrdered && !event.vehicleAvailableNotified && view !== 'month' && (
            <div style={markupTextStyle}>
              <img src={DeployedCodeHistory} alt='DeployedCodeHistory' />
              &nbsp;
              <b>PIÈCE(S) COMMANDÉE(S)</b>
            </div>
          )}
          {event.customer !== undefined && event.type === EventType.APPOINTMENT && (
            <>
              <Box style={flexStart}>
                <Typography variant='body2' sx={{ mr: 1 }}>
                  Client :{' '}
                </Typography>
                <Typography variant='body2'>
                  <strong>
                    {event.customer?.firstname} {event.customer?.lastname}
                  </strong>
                </Typography>
              </Box>

              {event.vehicle?.brand && event.vehicle?.model && (
                <Box style={flexStart}>
                  <Typography variant='body2' sx={{ mr: 1 }}>
                    Véhicule :{' '}
                  </Typography>
                  <Typography variant='body2'>
                    {startCase(lowerCase(event.vehicle?.brand))} - {startCase(lowerCase(event.vehicle?.model))}
                  </Typography>
                </Box>
              )}
            </>
          )}

          {showUserAvatar && (
            <Box style={flexStart}>
              <Typography variant='body2' sx={{ mr: 1 }}>
                Réparateur :{' '}
              </Typography>
              <UserAvatar size='small' avatar={employee?.avatar || ''} alt={employee?.firstname || ''} />
              <Typography variant='body2'>
                {employee ? `${employee.firstname} ${employee.lastname}` : 'Non affecté'}
              </Typography>
            </Box>
          )}

          {event.vehicleReceived && (
            <Box style={flexStart}>
              <Typography variant='body2' sx={{ mr: 1 }}>
                <i className='fas fa-solid fa-car-on ' style={{ fontSize: '1.1rem', marginRight: '4px' }} />
                Véhicule réceptionné
              </Typography>
            </Box>
          )}
        </Box>
      }
      placement='top-start'
      color={
        event.state === EventState.NEW
          ? isWithinLastIndicatedHours(event.start, FIRE_EVENT_DEFAULT_HOURS)
            ? 'red'
            : '#00BF08'
          : getEventStateColor(event, theme)
      }
      open={isControlled ? openTooltip : event.editable ? undefined : false} // Utilisez `undefined` pour laisser la Tooltip non contrôlée pour les événements non-NEW
      onClose={handleTooltipClose}
      // On baisse le zIndex du popper pour éviter de passer au dessus d'une boite de dialogue (détail d'un RDV par exemple)
      slotProps={{
        popper: {
          sx: {
            zIndex: 1000,
          },
        },
        tooltip: {
          sx: {
            border: '1px solid white',
            minWidth: '100px',
          },
        },
      }}
    >
      <div>
        <div
          style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: 8, backgroundColor: event?.color }}
        />
        <div style={getEventStyle(event)}>
          <div style={diagonalStyle} />
          <div
            style={{ marginBottom: 3, display: 'flex', flexDirection: 'column' }}
            className='my-calendar-event-label'
          >
            <div style={{ marginBottom: teamManagement ? 8 : 2 }}>
              {teamManagement && showUserAvatar && (
                <div style={avatarStyle}>
                  {event.employees && (
                    <UserAvatar
                      sx={{ position: view !== 'month' ? 'fixed' : 'relative' }}
                      showBorder
                      avatar={event.employees?.length > 0 ? event.employees[0].avatar : ''}
                      alt={event.employees?.length > 0 ? event.employees[0].firstname : ''}
                      size='small'
                      placeholder={false}
                    />
                  )}
                </div>
              )}
              <Box sx={{ position: 'absolute', top: '12px', right: '12px' }}>
                  {event.vehicleAvailableNotified && view !== 'month' && (
                    <div style={markupIconStyle}>
                      <MarkChatReadIcon sx={{ color: theme.palette.grey[800] }} />
                    </div>
                  )}
                  {event.hasProductOrdered && !event.vehicleAvailableNotified && view !== 'month' && (
                    <div style={markupIconStyle}>
                      <img src={DeployedCodeHistory} alt='DeployedCodeHistory' />{' '}
                    </div>
                  )}
              </Box>
              {event.type === EventType.APPOINTMENT && (
                <>
                  <strong
                    style={{
                      position: 'relative',
                      top: '2px',
                      marginLeft: `${getFirstLineMarginLeft()}px`,
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        top: '-1px',
                        marginLeft: `${getFirstLineMarginLeft() - 18}px`,
                        display: event?.vehicleReceived ? 'block' : 'none', //TODO : activer si usage avéré
                      }}
                    >
                      <i className='fas fa-solid fa-car-on ' style={{ fontSize: '1rem' }} />
                    </div>
                    {/*                 <VehicleIcon style={{ fontSize: 13, position: 'relative', top: '2px', }} />&nbsp; */}
                    {formatFrenchVehiclePlate(event.vehicle?.plate)} (&nbsp;{event.customer?.lastname}&nbsp;)
                  </strong>
                  <br />
                </>
              )}
            </div>

            {/*           {(view !== 'month' || (view === 'month' && event.type !== EventType.UNAVAILABILITY)) && ( */}
            <>
              <span>
                <b>{event.title}</b>
                <br />
              </span>
              {event.type === EventType.NOTE && event.notes && !isDayEvent && (
                <span style={{ marginTop: '10px' }}>{event.notes}</span>
              )}
            </>
            {/*           )} */}
          </div>
          {view !== 'month' && event.type === EventType.APPOINTMENT && !isMinDuration && (
            <span className='my-calendar-event-label'>
              {format(event.start!, 'HH:mm')}&nbsp;à&nbsp;{format(event.end!, 'HH:mm')}
            </span>
          )}

          {view !== 'month' && event.type === EventType.NOTE && (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div
                id='folded-corner'
                style={{
                  position: 'absolute',
                  right: '0',
                  bottom: eventDuration && (eventDuration < 30 || isDayEvent) ? '0' : '-10px',
                  width: '0',
                  height: '0',
                  borderTop: `25px solid ${darken('#4285F4', 0.1)}`,
                  borderRight: '25px solid #fff',
                }}
              />
            </div>
          )}
        </div>
      </div>
    </CustomTooltip>
  );
};

export default MyCalendarEvent;
