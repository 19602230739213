import React, { useEffect } from 'react';
import type { FunctionComponent } from 'react';
import type { PricingPackageProps } from '../PricingPackage';
import { Box, Typography, IconButton, TextField } from '@mui/material';
import { flexCenterCol } from '../../../../helpers/Tools';
import { styleFormation } from '../pricingTools';
import CloseIcon from '@mui/icons-material/Close';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import { useFormik } from 'formik';
import * as Yup from 'yup';

interface FormationOffersProps extends PricingPackageProps {
    giftFormationSupp: boolean;
    formationSupp: number;
    giftFormation: () => void;
    handleChangeFormationSupp: (e: React.ChangeEvent<HTMLInputElement>) => void;
    initData?: number;
}

export interface FormationOffersData{
  id: number;
  name: string;
  description: string;
  offers: Array<{ id: number; include: boolean; optional: boolean, description: string,
    values: [{id: number, description: string, price: number, quantity: number}] 
  }>;

}


const FormationOffers: FunctionComponent<FormationOffersProps> = ({
  movaPackage,
  options,
  giftFormationSupp,
  formationSupp,
  giftFormation,
  handleChangeFormationSupp,
  initData = 0,
  onlyView
}: FormationOffersProps) => {
  const { values, handleChange } = useFormik<{ formationSupp: number }>({
    initialValues: {
      formationSupp: initData,
    },
    onSubmit: () => {},
    validationSchema: Yup.object().shape({
      repaireSupp: Yup.number().optional().min(0),
    }),
    enableReinitialize: true,
  });

  const combinedHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event);
    handleChangeFormationSupp(event);  
  };

  useEffect(() => {
    handleChangeFormationSupp({
      target: {
        name: 'formationSupp',
        value: initData.toString(),
      },
    } as React.ChangeEvent<HTMLInputElement>);
  }, []);


  return (
    <Box style={flexCenterCol} sx={{minHeight: '16%', width: '100%', flexDirection: 'column'}}>
    {options?.formation
        ?.filter((item: FormationOffersData) => item.id === 11)
        .map((item: FormationOffersData) => (
          <React.Fragment key={item.id}>
            <Box style={{ ...flexCenterCol, ...styleFormation }}>
              {item.offers.filter((offre: FormationOffersData["offers"][0]) => offre.id === movaPackage.id)[0]?.include ? (
                <Typography variant='subtitle1' sx={{ marginTop: 1 }}>
                  {item.offers.filter((offre: FormationOffersData["offers"][0]) => offre.id === movaPackage.id)[0].description}
                </Typography>
              ) : (
                <CloseIcon className='CloseIcon' />
              )}
            </Box>
          </React.Fragment>
        ))}

      {options?.formation
        ?.filter((item: FormationOffersData) => item.id === 12)
        .map((item: FormationOffersData) => (
          <React.Fragment key={item.id}>
            <Box
              key={item.id}
              style={{ ...flexCenterCol, ...styleFormation }}
              sx={{ width: '85%', flexDirection: 'row', minHeight: '66px !important' }}
            >
              <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
                <IconButton onClick={giftFormation} sx={{ margin: 'auto' }}>
                  <CardGiftcardOutlinedIcon />
                </IconButton>
                <TextField
                  size='small'
                  disabled={onlyView}
                  fullWidth
                  name='formationSupp'
                  label={item.name}
                  value={values.formationSupp}
                  onChange={combinedHandleChange}
                  type='number'
                  variant='outlined'
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </Box>

              <Typography
                variant='body2'
                className='my-color'
                sx={{
                  minHeight: '20px',
                  textDecoration: giftFormationSupp ? 'line-through' : 'none',
                }}
              >
                {formationSupp !== 0 && `+${formationSupp.toFixed(2)}€`}
              </Typography>
            </Box>
          </React.Fragment>
        ))}
    </Box>
  );
};
export default FormationOffers;
