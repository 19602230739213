import { formatVehicleTire, MovaVehicleTireField, type Vehicle, VehiclePlateField } from '@movalib/movalib-commons';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  DialogContentText,
  Typography,
  DialogContent,
  Button,
  DialogActions,
} from '@mui/material';
import { noop } from 'lodash';
import { Fragment, memo, useCallback, useEffect, useState } from 'react';
import theme from '../../../theme';
import { useFormik } from 'formik';
import { useGetVehicleDetails } from '../../../query/vehicle/VehicleQuery';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { flexCenterCol } from '../../../helpers/Tools';
import CarCounter from '../../../assets/images/car_counter.png';
import TirePicture from '../../../assets/images/flanc_pneu.png';

type VehicleFormProps = {
  onSubmitForm?: (v: VehicleForm) => Promise<void>;
  onCancelForm: () => void;
  vehicle?: Vehicle;
  onFormChange?: (v: VehicleForm) => void;
};

export const VehicleSchema = Yup.object({
  plate: Yup.string().min(1).required('Vous devez renseigner la plaque'),
  currentMileage: Yup.number().default(0).min(0),
  averageMileagePerYear: Yup.number().default(0).min(0),
  tireInfos: Yup.object({
    width: Yup.string().length(3).required(),
    height: Yup.string().length(2).required(),
    diameter: Yup.string().length(3).required(),
    speedIndex: Yup.string().length(3).required(),
  }),
});

export type VehicleForm = Yup.InferType<typeof VehicleSchema>;

export const VehicleForm = memo(({ vehicle, onSubmitForm, onCancelForm, onFormChange }: VehicleFormProps) => {
  const [requestedPlate, setRequestedPlate] = useState<string>('');
  const [foundVehicle, setFoundVehicle] = useState<string>(vehicle?.id.toString() ?? '');

  const vehicleForm = useFormik<VehicleForm>({
    initialValues: {
      plate: vehicle?.plate ?? '',
      currentMileage: vehicle?.currentMileage ?? 0,
      averageMileagePerYear: vehicle?.averageMileagePerYear ?? 0,
      tireInfos: {
        width: vehicle?.tireWidth ?? '',
        height: vehicle?.tireHeight ?? '',
        diameter: vehicle?.tireDiameter ?? '',
        speedIndex: vehicle?.tireSpeedIndex ?? '',
      },
    },
    validationSchema: VehicleSchema,
    onSubmit: noop,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (onFormChange) {
      onFormChange(vehicleForm.values);
    }
  }, [vehicleForm.values, onFormChange]);

  const submitForm = () => {
    if (onSubmitForm) {
      onSubmitForm(vehicleForm.values); // Vérifie que `onSubmitForm` est défini avant de l'appeler
    }
  };

  const { mutateAsync: getVehicleDetails, isPending } = useGetVehicleDetails();

  const onChangeTireInfosProxy = useCallback(
    (tireInfos: VehicleForm['tireInfos']) => {
      vehicleForm.setFieldValue('tireInfos', tireInfos);

      const formattedTireInfo = formatVehicleTire(tireInfos).replace(/[^0-9A-QS-Za-qs-z]/g, '');
      if (
        (formattedTireInfo.length !== 0 && formattedTireInfo.length < 10) ||
        (formattedTireInfo.length === 10 && !new RegExp(/^\d{9}[A-Za-z]$/).test(formattedTireInfo))
      ) {
        vehicleForm.setStatus('Informations de pneus invalides');
        return;
      }
      vehicleForm.setStatus('');
    },
    [vehicleForm.setFieldValue],
  );

  const onValidPlateProxy = useCallback(
    async (plate: string) => {
      // While the plate is the same as before, do nothing (infinite loop)
      if (requestedPlate === plate) {
        return;
      }

      setRequestedPlate(plate);
      setFoundVehicle('');

      const response = await getVehicleDetails(plate);
      if (response.success && response.data) {
        await vehicleForm.setFieldValue('plate', plate);
        setFoundVehicle(response.data);
      } else {
        if (!String(response.error).includes('404')) {
          await vehicleForm.setFieldValue('plate', '');
          vehicleForm.setFieldError('plate', response.error);
        } else {
          await vehicleForm.setFieldValue('plate', '');
          vehicleForm.setFieldError('plate', 'Identification du véhicule impossible');
        }
      }
    },
    [vehicleForm.setFieldValue, getVehicleDetails, requestedPlate],
  );

  return (
    <>
      <DialogContent>
        <Grid container spacing={2}>
          {!vehicle?.id && (
            <>
              {' '}
              <Grid item xs={12}>
                <VehiclePlateField onValidVehiclePlate={onValidPlateProxy} />
                {vehicleForm.errors.plate && (
                  <Alert severity='error' sx={{ mt: 2 }}>
                    {vehicleForm.errors.plate}
                  </Alert>
                )}
              </Grid>
            </>
          )}
          <Grid item xs={1} style={flexCenterCol}>
            <img
              src={CarCounter}
              style={{
                position: 'relative',
                width: '100%',
                opacity: 0.5,
                zIndex: 200,
              }}
              alt='Icone Pneumatique'
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              type='number'
              fullWidth
              margin='normal'
              id='currentMileage'
              label='Kilométrage (facultatif)'
              name='currentMileage'
              value={vehicleForm.values.currentMileage}
              onChange={vehicleForm.handleChange}
            />
          </Grid>
          <Grid item xs={5}>
            <FormControl fullWidth margin='normal'>
              <InputLabel id='averageMileagePerYear-label'>Km Moyen Annuel (facultatif)</InputLabel>
              <Select
                labelId='averageMileagePerYear-label'
                id='averageMileagePerYear'
                name='averageMileagePerYear'
                value={vehicleForm.values.averageMileagePerYear}
                onChange={vehicleForm.handleChange}
                label='Kilométrage moyen annuel'
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={5000}>5 000</MenuItem>
                <MenuItem value={10000}>10 000</MenuItem>
                <MenuItem value={15000}>15 000</MenuItem>
                <MenuItem value={20000}>20 000</MenuItem>
                <MenuItem value={25000}>25 000</MenuItem>
                <MenuItem value={30000}>30 000</MenuItem>
                <MenuItem value={50000}>50 000</MenuItem>
                <MenuItem value={75000}>75 000</MenuItem>
                <MenuItem value={100000}>100 000</MenuItem>
                <MenuItem value={999999}>+100 000</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={1} style={flexCenterCol}>
            <img
              src={TirePicture}
              style={{
                position: 'relative',
                width: '100%',
                opacity: 0.4,
                zIndex: 200,
              }}
              alt='Icone Pneumatique'
            />
          </Grid>
          <Grid item xs={11}>
            <MovaVehicleTireField
              onChangeVehicleTire={onChangeTireInfosProxy}
              vehicleTire={vehicleForm.values.tireInfos}
            />
            {vehicleForm.status && (
              <Alert severity='error' sx={{ mt: 2 }}>
                {vehicleForm.status}
              </Alert>
            )}
          </Grid>
        </Grid>
        <DialogContentText id='add-vehicle-dialog-description' align='center'>
          {foundVehicle && !vehicle?.id && (
            <Fragment>
              <Typography variant='h6' color={theme.palette.primary.main} sx={{ fontSize: '16px', mt: 2 }}>
                <b>{vehicleForm.values.plate}</b>, véhicule trouvé
              </Typography>
              <p />
              <Typography variant='body2' color={theme.palette.text.primary}>
                <b>{foundVehicle}</b>
              </Typography>
            </Fragment>
          )}
        </DialogContentText>
      </DialogContent>
      {!onFormChange && (
        <DialogActions
          sx={{
            backgroundColor: theme.palette.grey[200],
            justifyContent: 'space-between',
          }}
        >
          <Button variant='contained' color='inherit' onClick={() => onCancelForm()}>
            Annuler
          </Button>
          <LoadingButton
            onClick={submitForm}
            disabled={!foundVehicle}
            variant='contained'
            color='primary'
            loading={isPending}
          >
            Enregistrer
          </LoadingButton>
        </DialogActions>
      )}
    </>
  );
});
