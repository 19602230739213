import { type Customer, type Vehicle, formatFrenchVehiclePlate } from '@movalib/movalib-commons';
import { Chip, DialogContent, Typography } from '@mui/material';
import { memo, useCallback, useMemo, useState } from 'react';
import { useBoolState } from '../../../helpers/hooks/useBoolState';
import theme from '../../../theme';
import { AddVehicle } from './AddVehicle';
import { EditVehicle } from './EditVehicle';

type CustomerVehiclesListProps = {
  customer: Customer;
};

export const CustomerVehiclesList = memo(({ customer }: CustomerVehiclesListProps) => {
  const { isEditVehicleModalOpen, toggleEditVehicleModalOpen } = useBoolState(false, 'editVehicleModalOpen');

  const [selectedVehicleId, setSelectedVehicleId] = useState<Vehicle['id'] | null>(null);

  const selectedVehicle = useMemo(
    () => (selectedVehicleId ? customer.vehicles.find((vehicle) => vehicle.id === selectedVehicleId) : null),
    [customer.vehicles, selectedVehicleId],
  );

  const onSelectVehicle = useCallback(
    (vehicleID: Vehicle['id']) => {
      setSelectedVehicleId(vehicleID);
      toggleEditVehicleModalOpen();
    },
    [toggleEditVehicleModalOpen],
  );

  const onCloseEditVehicleDialog = useCallback(() => {
    setSelectedVehicleId(null);
    toggleEditVehicleModalOpen();
  }, [toggleEditVehicleModalOpen]);

  return (
    <>
      <AddVehicle customer={customer} />

      <DialogContent>
        {customer.vehicles?.length === 0 ? (
          <div
            style={{
              overflow: 'visible',
              position: 'relative',
              border: '1px solid rgba(0, 0, 0, 0.12)',
              padding: '20px 0',
              marginTop: '10px',
            }}
          >
            <Typography variant='subtitle1' component='div' align='center' color={theme.palette.grey[500]}>
              Aucun résultat.
            </Typography>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '8px',
              alignItems: 'start',
              maxHeight: '400px',
              overflow: 'auto',
            }}
          >
            {customer.vehicles?.map((vehicle) => (
              <Chip
                key={vehicle.id}
                label={
                  <Typography variant='body2' sx={{ p: 1 }}>
                    <b>{formatFrenchVehiclePlate(vehicle.plate)}</b>
                    <br />
                    {vehicle.brand}&nbsp;{vehicle.model}&nbsp;{vehicle.version}
                  </Typography>
                }
                onClick={() => onSelectVehicle(vehicle.id)}
                sx={{ my: 0.5, borderRadius: 2, height: '100%', mr: 1, cursor: 'pointer' }}
              />
            ))}
          </div>
        )}
        {selectedVehicle && (
          <EditVehicle
            vehicle={selectedVehicle}
            customer={customer}
            open={isEditVehicleModalOpen}
            onClose={onCloseEditVehicleDialog}
          />
        )}
      </DialogContent>
    </>
  );
});
