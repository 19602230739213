import { flexCenter, flexStart } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import React, { type FunctionComponent } from 'react';
import { flexCenterCol } from '../../../helpers/Tools';
import { styleClassique, styleFormation, styleHeaders, stylePLV, styleReparateur } from './pricingTools';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import type { MovaPackage } from './PricingPackage';

type LegendOffersProps = {
  options: {
    classique?: Array<{
      id: number;
      name: string;
      description: string;
    }>;
    reparateur?: Array<{
      id: number;
      name: string;
    }>;
    visibilite?: Array<{
      id: number;
      name: string;
      description: string;
    }>;
    formation?: Array<{
      id: number;
      name: string;
      description: string;
    }>;
    web?: Array<{
      id: number;
      name: string;
      description: string;
    }>;
  };
  withIcon?: boolean;
  onlyView?: boolean;
  currentPackageSelected?: MovaPackage;
};

const LegendOffers: FunctionComponent<LegendOffersProps> = ({
  options,
  withIcon = true,
  onlyView = false,
  currentPackageSelected,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        minWidth: onlyView ? '50%' : '28%',
        maxWidth: onlyView ? '50%' : '28%',
        flexDirection: 'column',
        border: '2px solid transparent',
        height: '100%',
      }}
      style={flexStart}
    >
      <Box style={{ ...flexCenter, ...styleHeaders }} sx={{ width: '100%', flexDirection: 'column' }}>
        <Typography variant='subtitle1' color={theme.palette.text.secondary} fontWeight={700}>
          {'Offre selectionnée'}
        </Typography>
        <Typography variant='h6' sx={{ color: currentPackageSelected?.color }} fontWeight={700}>
          {currentPackageSelected?.name}
        </Typography>
        <Divider sx={{ px: 2, width: '80%', mt: '26px' }} />
      </Box>

      <Box style={{ ...flexCenter }} sx={{ width: '100%', flexDirection: 'row', flex: 1 }}>
        {withIcon && <InventoryOutlinedIcon color='primary' sx={{ fontSize: '60px' }} />}
        <Box style={{ ...flexCenter }} sx={{ width: '100%', flexDirection: 'column', height: '100%' }}>
          {options?.classique?.map((item) => (
            <React.Fragment key={item.id}>
              <Box style={{ ...flexCenterCol, ...styleClassique }} sx={{ width: '100%', flexDirection: 'column' }}>
                <Typography variant='subtitle1' sx={{ margin: 0, padding: 0 }}>
                  {item.name}
                </Typography>
                {/* <Typography variant="body1" sx={{ margin: 0, padding: 0 }}>{item.description}</Typography> */}
              </Box>
            </React.Fragment>
          ))}
        </Box>
      </Box>
      <Divider sx={{ px: 2, width: '80%' }} />

      <Box style={{ ...flexCenter }} sx={{ width: '100%', flexDirection: 'row', flex: 1, minHeight: '21%' }}>
        {withIcon && <EngineeringOutlinedIcon color='primary' sx={{ fontSize: '60px' }} />}
        <Box style={{ ...flexCenter }} sx={{ width: '100%', flexDirection: 'column', height: '100%' }}>
          {options?.reparateur?.map((item) => (
            <React.Fragment key={item.id}>
              <Box
                style={{ ...flexCenterCol, ...styleReparateur }}
                sx={{ width: '100%', minHeight: item.id === 8 ? '66px !important' : '' }}
              >
                <Typography variant='subtitle1' sx={{ margin: 0, padding: 0 }}>
                  {item.name}
                </Typography>
              </Box>
            </React.Fragment>
          ))}
        </Box>
      </Box>
      <Divider sx={{ px: 2, width: '80%' }} />
      <Box style={{ ...flexCenter }} sx={{ width: '100%', flexDirection: 'row', flex: 1, minHeight: '17%' }}>
        {withIcon && <LocalPrintshopOutlinedIcon color='primary' sx={{ fontSize: '60px' }} />}
        <Box style={{ ...flexCenter }} sx={{ width: '100%', flexDirection: 'column', height: '100%' }}>
          {options?.visibilite?.map((item) => (
            <React.Fragment key={item.id}>
              <Box
                style={{ ...flexCenterCol, ...stylePLV }}
                sx={{ width: '100%', flexDirection: 'column', minHeight: item.id === 10 ? '66px !important' : '' }}
              >
                <Typography variant='subtitle1' color={'rgb(60, 64, 67)'} sx={{ margin: 0, padding: 0 }}>
                  {item.name}
                </Typography>
                {/* <Typography variant="body1" sx={{ margin: 0, padding: 0 }}>{item.description}</Typography> */}
              </Box>
            </React.Fragment>
          ))}
        </Box>
      </Box>
      <Divider sx={{ px: 2, width: '80%' }} />
      <Box style={{ ...flexCenter }} sx={{ width: '100%', flexDirection: 'row', flex: 1, minHeight: '16%' }}>
        {withIcon && <SchoolOutlinedIcon color='primary' sx={{ fontSize: '60px' }} />}
        <Box style={{ ...flexCenter }} sx={{ width: '100%', flexDirection: 'column', height: '100%' }}>
          {options?.formation?.map((item) => (
            <React.Fragment key={item.id}>
              <Box
                style={{ ...flexCenterCol, ...styleFormation }}
                sx={{ width: '100%', flexDirection: 'column', minHeight: item.id === 12 ? '66px !important' : '' }}
              >
                <Typography variant='subtitle1' sx={{ margin: 0, padding: 0 }}>
                  {item.name}
                </Typography>
                {/* <Typography variant="body1" sx={{ margin: 0, padding: 0 }}>{item.description}</Typography> */}
              </Box>
            </React.Fragment>
          ))}
        </Box>
      </Box>
      <Divider sx={{ px: 2, width: '80%' }} />
      <Box style={{ ...flexCenter }} sx={{ width: '100%', flexDirection: 'row', flex: 1 }}>
        {withIcon && <LanguageOutlinedIcon color='primary' sx={{ fontSize: '50px' }} />}
        <Box style={{ ...flexCenter }} sx={{ width: '100%', flexDirection: 'column', height: '100%' }}>
          {options?.web?.map((item) => (
            <React.Fragment key={item.id}>
              <Box style={{ ...flexCenterCol, ...styleFormation }} sx={{ width: '100%', flexDirection: 'column' }}>
                <Typography variant='subtitle1' sx={{ margin: 0, padding: 0 }}>
                  {item.name}
                </Typography>
                {/* <Typography variant="body1" sx={{ margin: 0, padding: 0 }}>{item.description}</Typography> */}
              </Box>
            </React.Fragment>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default LegendOffers;
