import { CSSProperties, useEffect, useState, type FunctionComponent } from 'react';
import pricingData from './pricing.json';
import PricingPackage from './PricingPackage';
import Box from '@mui/material/Box';
import { flexCenter } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import LegendOffers from './LegendOffers';
import { height } from 'dom-helpers';
import { set } from 'lodash';
import { SubscriptionPaymentInterval } from '@movalib/movalib-commons';
import { darken, Grid, Typography, Alert, TextField, Tabs, FormControl, Input, InputAdornment } from '@mui/material';
import theme from '../../../theme';
import { StyledTab } from '../../../helpers/Styled';

export const styleHeaders: CSSProperties = {
  // maxHeight: '150px',
  minHeight: '120px',
};

export const styleClassique: CSSProperties = {
  minHeight: '28px',
  flexGrow: 1,
  flex: 1,
};

export const styleReparateur: CSSProperties = {
  minHeight: '32px',
  flex: 1,
};

export const stylePLV: CSSProperties = {
  minHeight: '32px',
  flex: 1,
};

export const styleFormation: CSSProperties = {
  minHeight: '32px',
  flex: 1,
};
interface PricingToolsPops {
  withIcon?: boolean;
  selectable?: boolean;
  workforce?: number;
  handleOfferChange?: (offer: any) => void;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

enum SubscriptionTabs {
  ALLOWANCE = 0,
  GAIN = 1,
  LAUNCH_OFFER = 2,
}

const PricingTools: FunctionComponent<PricingToolsPops> = ({
  withIcon = true,
  selectable = true,
  workforce = 0,
  handleOfferChange,
}: PricingToolsPops) => {
  const [data, setData] = useState<any>(null);
  const [currentSelectedPackage, setCurrentSelectedPackage] = useState<number>();
  const [tabValue, setTabValue] = useState<SubscriptionTabs>(SubscriptionTabs.ALLOWANCE);
  const [currentOffer, setCurrentOffer] = useState<any>();
  const [dailyInvoices, setDailyInvoices] = useState<number>(6);
  const [averageRate, setAverageRate] = useState<number>(4);
  const [averageRateRatio, setAverageRateRatio] = useState<number>(75);

  const [averageEstimate, setAverageEstimate] = useState<number>(2);
  const [averageEstimateRatio, setAverageEstimateRatio] = useState<number>(220);

  const [averageCostAppointment, setAverageCostAppointment] = useState<number>(1);
  const [averageCostAppointmentRatio, setAverageCostAppointmentRatio] = useState<number>(220);

  const selectedPackage = (id: number) => {
    setCurrentSelectedPackage(id);
  };

  useEffect(() => {
    // Charger les données JSON à partir de l'import
    setData(pricingData);
    if (selectable) {
      setCurrentSelectedPackage(1);
    }
  }, []);
  const handleChangeOffer = (offer: any) => {
    setCurrentOffer(offer);
    if (handleOfferChange) {
      handleOfferChange(offer);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const getRdvRatioCost = (): number => {
    if (currentOffer?.totalPrice) {
      return currentOffer.totalPrice / (dailyInvoices * 20);
    }
    return 0;
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ pt: 3, px: 2 }}>{children}</Box>}
      </div>
    );
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 1, height: '100%' }}>
        <LegendOffers withIcon={withIcon} options={data?.options} currentPackageSelected={currentOffer?.movaPackage} />
        {data?.offers?.map((offer: any) => (
          <PricingPackage
            key={offer.id}
            movaPackage={offer}
            options={data?.options}
            selectable={selectable}
            workforce={workforce}
            handleOfferChange={handleChangeOffer}
            setPackageSelected={selectedPackage}
            packageSelected={currentSelectedPackage}
          />
        ))}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', pt: 7 }}>
        <Box sx={{ minWidth: '30%' }} />
        <Box sx={{ flexDirection: 'column', width: '70%' }} style={flexCenter}>
          <TextField
            id='daily-appointments'
            label='FACTURES JOUR'
            value={dailyInvoices}
            type='number'
            required
            size='small'
            onChange={(e) => {
              setTabValue(SubscriptionTabs.ALLOWANCE);
              setDailyInvoices(Math.max(1, Number(e.target.value)));
            }}
            helperText='Nombre de factures / jour'
            sx={{ width: '90%', mt: 2.5 }}
            InputProps={{ inputProps: { min: 1 } }}
          />

          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant='fullWidth'
            sx={{ width: '100%' }}
            aria-label='basic tabs example'
            centered
          >
            <StyledTab label={<span>🪙&nbsp;&nbsp;Amortissement</span>} {...a11yProps(0)} />
            <StyledTab label={<span>📈&nbsp;&nbsp;Croissance CA / mois</span>} {...a11yProps(1)} />
          </Tabs>
          {/* Amortissement */}
          <CustomTabPanel value={tabValue} index={SubscriptionTabs.ALLOWANCE}>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              p={2}
              boxShadow={2}
              borderRadius={2}
              style={{
                maxWidth: 350,
                margin: 'auto',
                marginTop: 10,
                border: `2px solid ${darken(theme.palette.primary.main, 0.2)}`,
              }}
            >
              <Grid container>
                <Grid item xs={12} style={flexCenter}>
                  <Typography variant='h5' component='h2' style={{ color: darken(theme.palette.text.secondary, 0.4) }}>
                    <b>
                      {' '}
                      {currentOffer?.totalPrice &&
                        new Intl.NumberFormat('fr-FR', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(getRdvRatioCost())}{' '}
                      €
                    </b>
                  </Typography>
                  <Typography
                    variant='h6'
                    component='h3'
                    style={{ marginLeft: 5, color: darken(theme.palette.text.secondary, 0.4) }}
                  >
                    /rdv <b>*</b>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box style={flexCenter} sx={{ mt: 4, mb: 1 }}>
              <Alert sx={{ borderRadius: 20 }} icon={<span />} className='styled-alert'>
                * Coût de revient estimé pour <b>{dailyInvoices * 20}</b> factures par mois.
              </Alert>
            </Box>
          </CustomTabPanel>

          {/* Gains perçus */}
          <CustomTabPanel value={tabValue} index={SubscriptionTabs.GAIN}>
            <Grid container spacing={1} style={flexCenter}>
              {/* MO */}
              <Grid item xs={4} padding={2}>
                <Typography
                  variant='h6'
                  style={flexCenter}
                  sx={{ color: theme.palette.text.secondary, fontSize: '1.2rem' }}
                >
                  <b>MO</b>
                </Typography>
                <Box sx={{ border: `3px solid ${'#F29ABA'}`, m: 1, py: 1 }} p={2} boxShadow={3} borderRadius={2}>
                  <FormControl variant='standard' sx={{ width: '50%', display: 'block', m: 'auto' }}>
                    <Input
                      id='average-rate'
                      type='number'
                      inputProps={{ min: '0' }}
                      value={averageRate}
                      onChange={(e) => {
                        setAverageRate(Number(e.target.value));
                      }}
                      endAdornment={<InputAdornment position='end'>H</InputAdornment>}
                    />
                  </FormControl>
                  <Typography
                    variant='h6'
                    sx={{
                      color: '#F29ABA',
                      mb: 0.5,
                      fontFamily: 'caveat',
                      fontSize: '1.9rem',
                      fontWeight: 'bold',
                    }}
                    style={flexCenter}
                  >
                    {averageRate * averageRateRatio} € / mois
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant='subtitle2'
                    style={flexCenter}
                    sx={{
                      color: theme.palette.text.secondary,
                      textAlign: 'center',
                      fontSize: '0.8rem',
                    }}
                  >
                    taux moyen &nbsp;
                    <FormControl variant='standard' sx={{ width: '20%' }}>
                      <Input
                        id='average-rate-ratio'
                        type='number'
                        inputProps={{ min: '0' }}
                        value={averageRateRatio}
                        onChange={(e) => {
                          setAverageRateRatio(Number(e.target.value));
                        }}
                      />
                    </FormControl>
                    &nbsp;€ HT / h
                  </Typography>
                </Box>
              </Grid>
              {/* Amortissement */}
              <Grid item xs={4} padding={2}>
                <Typography
                  variant='h6'
                  style={flexCenter}
                  sx={{ color: theme.palette.text.secondary, fontSize: '1.2rem' }}
                >
                  <b>DEVIS</b>
                </Typography>
                <Box
                  sx={{ border: `3px solid ${theme.palette.secondary.main}`, m: 1, py: 1 }}
                  p={2}
                  boxShadow={2}
                  borderRadius={2}
                >
                  <FormControl variant='standard' sx={{ width: '50%', display: 'block', m: 'auto' }}>
                    <Input
                      id='average-estimate'
                      inputProps={{ min: '0' }}
                      type='number'
                      value={averageEstimate}
                      onChange={(e) => {
                        setAverageEstimate(Number(e.target.value));
                      }}
                    />
                  </FormControl>
                  <Typography
                    variant='body2'
                    sx={{
                      color: darken(theme.palette.secondary.main, 0.1),
                      mb: 0.5,
                      fontFamily: 'caveat',
                      fontSize: '1.9rem',
                      fontWeight: 'bold',
                    }}
                    style={flexCenter}
                  >
                    {averageEstimate * averageEstimateRatio} € / mois
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant='subtitle2'
                    style={flexCenter}
                    sx={{
                      color: theme.palette.text.secondary,
                      textAlign: 'center',
                      fontSize: '0.8rem',
                    }}
                  >
                    montant moyen devis &nbsp;
                    <FormControl variant='standard' sx={{ width: '20%' }}>
                      <Input
                        id='average-estimate-ratio'
                        type='number'
                        inputProps={{ min: '0' }}
                        value={averageEstimateRatio}
                        onChange={(e) => {
                          setAverageEstimateRatio(Number(e.target.value));
                        }}
                      />
                    </FormControl>
                    &nbsp; €
                  </Typography>
                </Box>
              </Grid>
              {/* Croissance CA */}
              <Grid item xs={4} padding={2}>
                <Typography
                  variant='h6'
                  style={flexCenter}
                  sx={{ color: theme.palette.text.secondary, fontSize: '1.2rem' }}
                >
                  <b>RDV</b>
                </Typography>
                <Box
                  sx={{ border: `3px solid ${theme.palette.primary.main}`, m: 1, py: 1 }}
                  p={2}
                  boxShadow={2}
                  borderRadius={2}
                >
                  <FormControl variant='standard' sx={{ width: '50%', display: 'block', m: 'auto' }}>
                    <Input
                      id='average-rate'
                      type='number'
                      inputProps={{ min: '0' }}
                      value={averageCostAppointment}
                      onChange={(e) => {
                        setAverageCostAppointment(Number(e.target.value));
                      }}
                    />
                  </FormControl>
                  <Typography
                    variant='body2'
                    sx={{
                      color: darken(theme.palette.primary.main, 0.1),
                      mb: 0.5,
                      fontFamily: 'caveat',
                      fontSize: '1.9rem',
                      fontWeight: 'bold',
                    }}
                    style={flexCenter}
                  >
                    {averageCostAppointment * averageCostAppointmentRatio} € / mois
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant='subtitle2'
                    style={flexCenter}
                    sx={{
                      color: theme.palette.text.secondary,
                      textAlign: 'center',
                      fontSize: '0.8rem',
                    }}
                  >
                    montant moyen rdv &nbsp;
                    <FormControl variant='standard' sx={{ width: '20%' }}>
                      <Input
                        id='average-estimate-ratio'
                        type='number'
                        inputProps={{ min: '0' }}
                        value={averageCostAppointmentRatio}
                        onChange={(e) => {
                          setAverageCostAppointmentRatio(Number(e.target.value));
                        }}
                      />
                    </FormControl>
                    &nbsp;€
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Box style={flexCenter} sx={{ mt: 3, mb: 1 }}>
              <Alert sx={{ borderRadius: 20, fontSize: '1.0rem' }} icon={<span />} className='styled-alert'>
                Soit, fourchette basse, une <b>croissance de CA</b> estimée à&nbsp;
                <b>
                  {averageRate * averageRateRatio +
                    averageCostAppointment * averageCostAppointmentRatio +
                    averageEstimate * averageEstimateRatio}
                  €
                </b>&nbsp;
                grâce à Movalib
              </Alert>
            </Box>
          </CustomTabPanel>
        </Box>
      </Box>
    </>
  );
};
export default PricingTools;
