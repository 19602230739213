import {
  type Garage,
  Logger,
  MovaAppType,
  SubscriptionPaymentInterval,
  User,
  UserService,
} from '@movalib/movalib-commons';
import { readCookie } from '@movalib/movalib-commons/dist/src/helpers/CookieUtils';
import { flexCenter } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import { Box, Container, CssBaseline, type SelectChangeEvent, useTheme } from '@mui/material';
import type React from 'react';
import { type FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import GreenLeafImage from '../../assets/images/leaf_green_large.png';
import PinkLeafImage from '../../assets/images/leaf_pink_large.png';
import PricingTable from '../../components/registration/PricingTable';
import SignUpHeader from '../../components/registration/SignUpHeader';
import { COOKIE_PRO_TOKEN, COOKIE_SELECTED_GARAGE_ID } from '../../helpers/Constants';
import { deleteCookie, deleteCookies } from '../../helpers/Tools';
import { setSnackbar } from '../../slices/snackbarSlice';
import PricingTools from './Pricing/pricingTools';

interface PricingProps {
  defaultWorkforce?: number;
  onWorkforceChange?: (value: number) => void;
  defaultSubscription?: SubscriptionPaymentInterval;
  onWSubscriptionChange?: (value: SubscriptionPaymentInterval) => void;
  pushSubscriptionCost: (value: number) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

enum SubscriptionTabs {
  ALLOWANCE = 0,
  GAIN = 1,
  LAUNCH_OFFER = 2,
}

const START_PACK_COST: number = 149.0;
const ONE_ADDITIONNAL_EMP_COST: number = 99.0;
const TWO_ADDITIONNALS_EMP_COST: number = 89.0;
const MORE_ADDITIONNALS_EMP_COST: number = 69.0;
const SMS_OPERATOR_UNIT: number = 250;

const Pricing: FunctionComponent<PricingProps> = ({
  defaultWorkforce = 1,
  onWorkforceChange,
  defaultSubscription = SubscriptionPaymentInterval.YEAR,
  onWSubscriptionChange,
  pushSubscriptionCost,
}) => {
  const isAuthenticated: boolean = Boolean(readCookie(COOKIE_PRO_TOKEN));
  const [connectedUser, setConnectedUser] = useState<User | undefined>();
  const [registeredGarages, setRegisteredGarages] = useState<Garage[] | []>();
  // Pour la popup de confirmation d'activation d'un garage
  const [openConfirmActivate, setOpenConfirmActivate] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const [selectedGarage, setSelectedGarage] = useState<Garage>();
  const [workforce, setWorkforce] = useState<number>(defaultWorkforce);
  const [subscriptionPayment, setSubscriptionPayment] = useState<SubscriptionPaymentInterval>(defaultSubscription);
  const [dailyInvoices, setDailyInvoices] = useState<number>(6);
  const [tabValue, setTabValue] = useState<SubscriptionTabs>(SubscriptionTabs.ALLOWANCE);
  const [starterPackCost, setStarterPackCost] = useState<number>();
  const [techniciansCost, setTechniciansCost] = useState<number>();
  const [subscriptionCost, setSubscriptionCost] = useState<number>();

  // Chargement initial de la page
  useEffect(() => {
    if (isAuthenticated) {
      // On contrôle que l'utilisateur dispose du rôle adéquat
      UserService.getCurrentUser(MovaAppType.GARAGE).then((response) => {
        if (response.success) {
          setConnectedUser(response.data);
          refreshRegisteredGarages();
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? "Chargement de votre compte impossible, contactez l'équipe Movalib",
              severity: 'error',
            }),
          );
        }
      });
    }
  }, [location.search]);

  // Chargement initial de la page
  useEffect(() => {
    refreshRegisteredGarages();
  }, [connectedUser]);

  useEffect(() => {
    refreshSubscriptionCost();
    // Appeler la fonction et mettre à jour l'état avec le nouveau coût
    setTechniciansCost(getAdditionnalTechnicianCost());
  }, [workforce]);

  useEffect(() => {
    refreshSubscriptionCost();
  }, [subscriptionPayment]);

  const refreshRegisteredGarages = () => {
    if (connectedUser) {
      // Chargement des inscriptions de garage rattachées au profil
      UserService.getSalesGarages(connectedUser.id).then((response) => {
        if (response.success) {
          Logger.info(response.data);
          setRegisteredGarages(response.data);
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? "Chargement des inscriptions impossible, contactez l'équipe Movalib",
              severity: 'error',
            }),
          );
        }
      });
    }
  };

  const getStarterPackCost = (): number => {
    // Coût annuel (prix défaut)
    let cost = START_PACK_COST;

    // On applique une majoration de 20% si souscription mensuelle
    if (subscriptionPayment === SubscriptionPaymentInterval.MONTH) {
      cost = cost + cost * 0.2;
    } else if (subscriptionPayment === SubscriptionPaymentInterval.QUARTER) {
      // On applique une majoration de 10% si souscription trimestrielle
      cost = cost + cost * 0.1;
    } else if (subscriptionPayment === SubscriptionPaymentInterval.TWO_YEARS) {
      // On applique une minoration de 16% si souscription 24 Mois pour obtenir 30% par rapport au prix Mensuel
      cost = cost - cost * 0.16;
    }

    return cost;
  };

  const getAdditionnalTechnicianCost = (): number => {
    let cost = 0.0;

    if (workforce === 2) {
      cost = ONE_ADDITIONNAL_EMP_COST;
    } else if (workforce === 3) {
      cost = TWO_ADDITIONNALS_EMP_COST;
    } else if (workforce >= 4) {
      cost = MORE_ADDITIONNALS_EMP_COST;
    }

    // On applique une majoration de 20% si souscription mensuelle
    if (subscriptionPayment === SubscriptionPaymentInterval.MONTH) {
      cost = cost + cost * 0.2;
    } else if (subscriptionPayment === SubscriptionPaymentInterval.TWO_YEARS) {
      // On applique une minoration de 16% si souscription 24 Mois pour obtenir 30% par rapport au prix Mensuel
      cost = cost - cost * 0.16;
    }

    return cost;
  };

  const refreshSubscriptionCost = () => {
    setStarterPackCost(getStarterPackCost());
    setTechniciansCost(getAdditionnalTechnicianCost());
    setSubscriptionCost(() => {
      // Tarif de base : starter pack + coût par réparateur
      const cost = (workforce - 1) * getAdditionnalTechnicianCost();
      const totalCost = getStarterPackCost() + cost;

      // On transmet au callback si présent
      if (pushSubscriptionCost) {
        pushSubscriptionCost(totalCost);
      }
      return totalCost;
    });
  };

  const isSignupAuthorized = (user: User | undefined) => {
    return user && (User.isSales(user) || User.isSuperAdmin(user));
  };

  const handlePageClick = (page: string) => {
    if (page) {
      // Cas particulier du Logout
      switch (page) {
        case 'LOGOUT':
          // Suppression des cookies
          deleteCookies();
          // Suppression de l'utilisateur connecté
          setConnectedUser(undefined);
          // Redirection vers le login
          history.push('/signup');
          break;

        case 'REGISTRATIONS':
          // Redirection vers le login
          history.push(`/registrations?key=${Date.now()}`);
          break;

        case 'SIGNUP':
          deleteCookie(COOKIE_SELECTED_GARAGE_ID);
          // Redirection vers le formulaire d'inscription
          history.push('/signup');
          break;
      }
    }
  };

  const handleWorkforceChange = (value: number) => {
    // Callback calling if available
    if (onWorkforceChange) {
      onWorkforceChange(value);
    }

    setWorkforce(value);
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ pt: 3, px: 2 }}>{children}</Box>}
      </div>
    );
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const getRdvRatioCost = (): number => {
    if (subscriptionCost) {
      return subscriptionCost / (dailyInvoices * 20);
    }
    return 0;
  };

  const handleSubscriptionChange = (e: SelectChangeEvent<string>): void => {
    const fieldValue: SubscriptionPaymentInterval = e.target.value as SubscriptionPaymentInterval;

    // Si engagement mensuel on force la valeur du tabValue à 1 (onglet Amortissement)
    if (fieldValue === SubscriptionPaymentInterval.MONTH && tabValue === SubscriptionTabs.LAUNCH_OFFER) {
      setTabValue(SubscriptionTabs.ALLOWANCE);
    }

    // Callback calling if available
    if (onWSubscriptionChange) {
      onWSubscriptionChange(fieldValue);
    }

    setSubscriptionPayment(fieldValue);
  };

  return (
    <>
      <div style={flexCenter}>
        <img
          src={GreenLeafImage}
          style={{
            position: 'fixed',
            float: 'left',
            width: '250px',
            height: '400px',
            top: '-20%',
            left: '0%',
            opacity: '0.3',
            display: connectedUser ? 'block' : 'none',
            zIndex: -8,
          }}
          alt='Feuille Verte Movalib'
        />

        <Container component='main' maxWidth='xl'>
          <CssBaseline />

          {connectedUser && isSignupAuthorized(connectedUser) && (
            <>
              <SignUpHeader
                title="Client(s) en cours d'inscription"
                connectedUser={connectedUser}
                onPageClick={handlePageClick}
              />
              <Box sx={{ width: '85%', pt: '125px', margin: 'auto', height: '85vh' }}>              
                <PricingTools />
              </Box>
            </>
          )}
        </Container>

        <img
          src={PinkLeafImage}
          style={{
            position: 'fixed',
            float: 'right',
            width: '250px',
            height: '400px',
            bottom: '-20%',
            right: '0%',
            opacity: '0.3',
            display: connectedUser ? 'block' : 'none',
            zIndex: '-10',
          }}
          alt='Feuille Rose Movalib'
        />
      </div>
    </>
  );
};

export default Pricing;
