import type React from 'react';
import { Box, FormControl, FormHelperText } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';

interface CustomTextareaProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  error?: string | undefined;
  placeholder?: string;
  helperText?: React.ReactNode;
  id?: string;
  name?: string;
}

const CustomTextarea: React.FC<CustomTextareaProps> = ({
  value,
  onChange,
  onBlur,
  error,
  placeholder = 'Notes internes',
  helperText,
  id,
  name,
}) => {
  return (
    <FormControl fullWidth error={Boolean(error)}>
      <Box
        sx={{
          border: '1px solid',
          borderColor: error ? 'red' : 'rgba(0, 0, 0, 0.23)',
          borderRadius: '4px',
          padding: '2px',
          '&:focus-within': {
            borderColor: 'primary.main',
            borderWidth: '2px',
          },
        }}
      >
        <TextareaAutosize
          id={id}
          name={name}
          aria-label={placeholder}
          minRows={3}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          style={{
            width: '96%',
            fontSize: '1rem',
            fontFamily: 'Outfit, sans-serif',
            padding: '8px',
            border: 'none',
            outline: 'none',
            resize: 'none',
          }}
        />
      </Box>
      <FormHelperText>{error ? error : helperText}</FormHelperText>
    </FormControl>
  );
};

export default CustomTextarea;
