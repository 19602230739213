import { validatePhoneNumber } from '@movalib/movalib-commons';
import CloseIcon from '@mui/icons-material/CloseRounded';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  type TextFieldProps,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { type FormikConfig, useFormik } from 'formik';
import { startCase } from 'lodash';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useCreateCustomer, useSearchCustomers } from '../../query/customer/CustomerQuery';
import { useFetchAdministratedGarages, useGarageAddCustomerVehicle } from '../../query/garage/GarageQuery';
import { setSnackbar } from '../../slices/snackbarSlice';
import theme from '../../theme';
import { VehicleForm } from './vehicle/vehicleForm';

const formSchema = Yup.object({
  email: Yup.string().email('Email invalide').optional().nullable(),
  firstname: Yup.string().required('Prénom requis'),
  lastname: Yup.string().required('Nom requis'),
  phoneNumber: Yup.string()
    .required('N° de téléphone requis')
    .test('len', 'Le n° de téléphone doit comporter 10 chiffres', (val) => val?.length === 10),
});

type Form = Yup.InferType<typeof formSchema>;

export type AddCustomerProps = {
  open: boolean;
  onClose: (customerLastName?: string) => void;
  onEntered?: () => void;
};

export const AddCustomer = memo(({ open, onClose }: AddCustomerProps) => {
  const dispatch = useDispatch();
  const [vehicle, setVehicle] = useState<VehicleForm | undefined>(undefined);
  const { data: garage } = useFetchAdministratedGarages();
  const [phoneNumber, setPhoneNumber] = useState('');

  const { mutateAsync: createCustomer } = useCreateCustomer();
  const { mutateAsync: createVehicle } = useGarageAddCustomerVehicle();
  const inputNameRef = useRef<HTMLInputElement>(null);

  const { data: customers } = useSearchCustomers(garage?.id ?? '', phoneNumber ?? '');

  useEffect(() => {
    createVehicleByCustomer();
  }, [customers]);

  useEffect(() => {
    setTimeout(() => {
      inputNameRef.current?.focus();
    });
  }, [open]);

  const createVehicleByCustomer = async () => {
    if (vehicle && customers && customers.length > 0) {
      setPhoneNumber(''); // for disabled loop effect with useSearchCustomers
      const { plate, averageMileagePerYear, currentMileage, tireInfos } = vehicle;
      const response = await createVehicle({
        garageId: garage?.id ?? '',
        customerId: customers[0].id,
        plate,
        currentMileage,
        averageMileagePerYear,
        tireWidth: tireInfos.width || undefined,
        tireHeight: tireInfos.height || undefined,
        tireDiameter: tireInfos.diameter || undefined,
        tireSpeedIndex: tireInfos.speedIndex || undefined,
      });

      if (response.success) {
        dispatch(
          setSnackbar({
            open: true,
            message: 'Le client a bien été ajouté avec son véhicule !',
            severity: 'success',
          }),
        );
      } else {
        dispatch(
          setSnackbar({
            open: true,
            message: "Le client a bien été ajouté mais une erreur est survenue lors de l'ajout du véhicule",
            severity: 'error',
          }),
        );
      }
      onClose(customers[0].lastname);
      resetForm();
    }
  };

  const handleFormChange = (values: VehicleForm) => {
    setVehicle(values);
  };

  const onSubmit: FormikConfig<Form>['onSubmit'] = useCallback(
    async (values) => {
      if (!garage?.id) {
        return;
      }
      const response = await createCustomer({
        garageId: garage.id,
        payload: { ...values, email: values?.email || null },
      });
      if (response.success) {
        if (vehicle?.plate) {
          setPhoneNumber(values.phoneNumber);
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.data ?? 'Le client a bien été ajouté',
              severity: 'success',
            }),
          );
          onClose(values.lastname);
          resetForm();
        }
      } else {
        dispatch(
          setSnackbar({
            open: true,
            message: response.error ?? "Erreur lors de l'ajout du client",
            severity: 'error',
          }),
        );
      }
    },
    [garage?.id, createCustomer, dispatch, onClose, vehicle],
  );

  const { values, errors, isValid, dirty, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik<Form>({
    initialValues: {
      email: null,
      firstname: '',
      lastname: '',
      phoneNumber: '',
    },
    validationSchema: formSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const handleChangeLastname = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      setFieldValue('lastname', e.target.value.toUpperCase());
    },
    [setFieldValue],
  );

  const handleChangeFirstName = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      // uppercase each 1rst letter on each word (Just Like This You Know)
      const words = e.target.value.split(/(\s+|-+)/g).map((word) => {
        // Only capitalize words that are not spaces or hyphens
        if (word.trim() === '' || word === '-') {
          return word;
        }
        // Ensure only the first character is capitalized, and others remain as they are
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      });

      setFieldValue('firstname', words.join(''));
    },
    [setFieldValue],
  );

  const handleChangePhoneNumber = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      if (e.target.value !== '' && !validatePhoneNumber(e.target.value)) {
        return;
      }
      setFieldValue('phoneNumber', e.target.value);
    },
    [setFieldValue],
  );

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth='sm' scroll='body'>
      <Toolbar
        disableGutters
        variant='dense'
        sx={{
          minHeight: 3,
          backgroundColor: theme.palette.grey[200],
          py: 0,
        }}
      >
        <DialogTitle sx={{ flexGrow: 1 }} component={'div'}>
          <Typography
            py={0}
            my={0}
            sx={{
              pl: '34px',
              color: theme.palette.text.primary,
              textAlign: 'center',
            }}
          >
            <span>
              AJOUTER <b>UN CLIENT</b>
            </span>
          </Typography>
        </DialogTitle>
        <Tooltip title='Fermer'>
          <IconButton sx={{ mr: 1 }} size='small' aria-label='close' onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>

      <form onSubmit={handleSubmit}>
        <DialogContent sx={{ pb: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                inputRef={inputNameRef}
                margin='normal'
                autoFocus
                required
                fullWidth
                id='lastname'
                label='NOM'
                name='lastname'
                autoComplete='family-name'
                onChange={handleChangeLastname}
                value={values.lastname}
                error={!!errors.lastname}
                helperText={errors.lastname}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='normal'
                required
                fullWidth
                id='firstname'
                label='Prénom'
                name='firstname'
                autoComplete='given-name'
                onChange={handleChangeFirstName}
                value={values.firstname}
                error={!!errors.firstname}
                helperText={errors.firstname}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type='tel'
                margin='normal'
                fullWidth
                required
                id='phoneNumber'
                label='N° de téléphone'
                name='phoneNumber'
                autoComplete='tel'
                onChange={handleChangePhoneNumber}
                value={values.phoneNumber}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
              />
            </Grid>
            <Grid item xs={6}>
              <Alert sx={{ height: '100%', p: 0 }}>
                L'email est facultatif, mais on vous encourage à le demander pour réduire le papier 🙏🌿 !
              </Alert>
            </Grid>

            <Grid item xs={12}>
              <TextField
                margin='normal'
                fullWidth
                id='email'
                label='Adresse email (facultative)'
                name='email'
                autoComplete='email'
                onChange={handleChange}
                value={values.email}
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <VehicleForm onCancelForm={onClose} onFormChange={handleFormChange} />
        <DialogActions
          sx={{
            backgroundColor: theme.palette.grey[200],
            justifyContent: 'space-between',
          }}
        >
          <Button variant='contained' color='inherit' onClick={() => onClose()}>
            Annuler
          </Button>
          <Button disabled={dirty ? !isValid : true} type='submit' variant='contained'>
            Enregistrer
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
});
