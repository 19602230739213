import type { FunctionComponent } from 'react';
import Box from '@mui/material/Box';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import type { SxProps, Theme } from '@mui/material';

interface UserAvatarProps {
  avatar: string;
  alt: string;
  size?: string;
  sx?: SxProps<Theme>;
  showBorder?: boolean;
  placeholder?: boolean;
}

const getAvatarSize = (size: string) => {
  switch (size) {
    case 'small':
      return '25px';
    case 'medium':
      return '50px';
    case 'large':
      return '100px';
    default:
      return '50px';
  }
};

const UserAvatar: FunctionComponent<UserAvatarProps> = ({
  avatar,
  alt,
  size = 'medium',
  sx,
  showBorder = false,
  placeholder = true,
}) => (
  <Box mr={1} display='flex' sx={{ ...sx }}>
    {avatar ? (
      <img
        src={avatar}
        alt={alt}
        style={{
          width: getAvatarSize(size),
          height: getAvatarSize(size),
          borderRadius: '50%',
          border: showBorder ? '2px solid white' : 'none',
        }}
      />
    ) : placeholder ? (
      <AccountCircleOutlinedIcon />
    ) : null}
  </Box>
);

export default UserAvatar;
